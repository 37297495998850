import { UtilsService } from '@/services/UtilsService';
import { useLinkSetupStore } from '@/stores/useLinkSetupStore';
import { faImage, faTimes, faUpload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Group, Text, TextInput, rem, Image, Button, Center } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import classes from '../LinkCreationModal.module.css';

export const CustomizeLinkPreview = () => {
  const [link, setLink] = useLinkSetupStore((state) => [state.link, state.setLink]);
  const [loadingImage, setLoadingImage] = useState(false);
  const [enableDropZone, setEnableDropZone] = useState(false);

  const handleFileDrop = async (files: any) => {
    if (files && files.length > 0) {
      const formData = new FormData();
      formData.append('input_file', files[0]);
      setLoadingImage(true);
      await new UtilsService()
        .uploadImage(formData)
        .then((res) => {
          if (res.data.status) {
            setLink({
              ...link,
              custom_link_preview_attributes: {
                ...link.custom_link_preview_attributes,
                image: res.data.url,
              },
            });
          }
        })
        .catch((err) => {
          if ((err.response.status === 400 || err.response.status === 422) && err?.response?.data?.message) {
            notifications.show({
              color: 'red',
              message: err.response.data.message,
            });
          }
        });

      setLoadingImage(false);
      // You can now use formData to send the file data to your server or perform any other actions.
    }
  };

  return (
    <Box p={16} className={!link.is_custom_link_preview_enabled ? classes.disabled : ''}>
      <Text fw={600} fz={14}>
        Customize Link Preview
      </Text>
      <TextInput
        label="Title"
        value={link.custom_link_preview_attributes.title || ''}
        variant="filled"
        mt={12}
        placeholder="Title"
        onChange={(event) => {
          setLink({
            ...link,
            custom_link_preview_attributes: {
              ...link.custom_link_preview_attributes,
              title: event.currentTarget.value,
            },
          });
        }}
      />
      <TextInput
        label="Description"
        value={link.custom_link_preview_attributes.description || ''}
        variant="filled"
        mt={12}
        placeholder="Description"
        onChange={(event) => {
          setLink({
            ...link,
            custom_link_preview_attributes: {
              ...link.custom_link_preview_attributes,
              description: event.currentTarget.value,
            },
          });
        }}
      />
      {link.custom_link_preview_attributes.image?.startsWith('http') ? <>
        <Image
          mt={12}
          w={480}
          h={255}
          fit="contain"
          style={{
            borderRadius: rem(4),
            border: `1px solid var(--mantine-color-gray-3)`,
          }}
          src={link.custom_link_preview_attributes.image || ''}
          alt="Link Preview Image"
        />
        <Center>
          <Button
            variant="outline"
            mt={12}
            color="red"
            onClick={() => {
              setLink({
                ...link,
                custom_link_preview_attributes: {
                  ...link.custom_link_preview_attributes,
                  image: '',
                },
              });
            }}
          >
            Remove Image
          </Button>
        </Center>
        </> : link?.link_preview_attributes?.images[0] && !enableDropZone ? <><Image
          mt={12}
          w={480}
          h={255}
          fit="contain"
          style={{
            borderRadius: rem(4),
            border: `1px solid var(--mantine-color-gray-3)`,
          }}
          src={link?.link_preview_attributes?.images[0] || ''}
          alt="Link Preview Image"
        />
          <Center>
            <Button
              variant="outline"
              mt={12}
              color="red"
              onClick={() => {
                setEnableDropZone(!enableDropZone)
              }}
            >
              Remove Image
            </Button>
          </Center>
          </> : (
        <>
          <Dropzone
            mt={12}
            onDrop={(files) => handleFileDrop(files)}
            onReject={(files) => console.log('rejected files', files)}
            maxSize={5 * 1024 ** 2}
            accept={IMAGE_MIME_TYPE}
          >
            <Group justify="center" gap="xl" mih={100} style={{ pointerEvents: 'none' }}>
              <Dropzone.Accept>
                <FontAwesomeIcon
                  color="gray.7"
                  size="sm"
                  icon={faUpload}
                  style={{
                    width: rem(32),
                    height: rem(32),
                    color: 'var(--mantine-color-blue-6)',
                  }}
                />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <FontAwesomeIcon
                  color="gray.7"
                  size="sm"
                  icon={faTimes}
                  style={{
                    width: rem(32),
                    height: rem(32),
                    color: 'var(--mantine-color-red-6)',
                  }}
                />
              </Dropzone.Reject>
              <Dropzone.Idle>
                <FontAwesomeIcon
                  color="gray.7"
                  size="sm"
                  icon={faImage}
                  style={{
                    width: rem(32),
                    height: rem(32),
                    color: 'var(--mantine-color-gray-4)',
                  }}
                />
              </Dropzone.Idle>

              <div>
                <Text size="sm" inline>
                  Drag image here or click to select file.
                </Text>
              </div>
            </Group>
          </Dropzone>
        </>
      )}
    </Box>
  );
};
