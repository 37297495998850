import { BioLinkBlockService } from '@/services/BioLinkBlockService';
import { useBioLinkBlockStore } from '@/stores/Bio/useBioLinkBlockStore';
import { useBioLinkSetupStore } from '@/stores/Bio/useBioLinkSetupStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { ensureHttps } from '@/utils/LinkUtility';
import { faLink, faText, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  Box,
  Button,
  Center,
  Divider,
  Fieldset,
  Flex,
  Group,
  Switch,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import {
  BIO_LINKS_SOCIAL_NETWORKS,
  DEFAULT_LINK_BLOCK_VALUES,
  findSocialNetworkByValue,
} from '@replug/utils';
import { useParams } from 'react-router-dom';

export const BioLinksSection = ({ reload }: { reload: () => void }) => {
  const { id } = useParams();
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [bioLinkBlock, setBioLinkBlock] = useBioLinkBlockStore((state) => [
    state.bioLinkBlock,
    state.setBioLinkBlock,
  ]);
  const [bioLink] = useBioLinkSetupStore((state) => [state.bioLink]);

  const handleAddLink = () => () => {
    if (bioLinkBlock.links.length < 5) {
      setBioLinkBlock({
        ...bioLinkBlock,
        links: [
          ...bioLinkBlock.links,
          {
            id: Math.random().toString(36).substr(2, 9),
            title: '',
            url: '',
            is_show_title_enabled: true,
          },
        ],
      });
    }
  };

  const handleAddBlock = () => {
    // validate the channel urls. Notify the mising ones
    if (bioLinkBlock.links.length === 0) {
      notifications.show({
        color: 'red',
        message: 'Please add at least one link',
      });
      return;
    }

    // Check if there is any missing title and url in the links

    const missingLinks = bioLinkBlock.links.filter((link) => !link.title || !link.url);
    if (missingLinks.length > 0) {
      notifications.show({
        color: 'red',
        message: 'Please fill in the missing titles and urls',
      });
      return;
    }

    // Make sure the URLs are valid ones

    const invalidLinks = bioLinkBlock.links.filter((link) => {
      try {
        new URL(link.url);
        return false;
      } catch (e) {
        return true;
      }
    });

    if (invalidLinks.length > 0) {
      notifications.show({
        color: 'red',
        message: 'Please make sure you add the valid URLs.',
      });
      return;
    }
    let payload = {
      ...bioLinkBlock,
      bio_link_id: id,
      workspace_id: defaultWorkspace?._id,
    };

    if (bioLinkBlock._id) {
      new BioLinkBlockService()
        .update(defaultWorkspace?._id || '', payload)
        .then((res) => {
          if (res.data.data) {
            notifications.show({
              color: 'green',
              message: res.data.message,
            });

            setBioLinkBlock(DEFAULT_LINK_BLOCK_VALUES);
            reload();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      payload.order = bioLink?.blocks.length + 1;

      if (!bioLink?._id) {
        notifications.show({
          color: 'red',
          message: 'Please first publish your bio link.',
        });
        return;
      }
      new BioLinkBlockService()
        .create(defaultWorkspace?._id || '', payload)
        .then((res) => {
          if (res.data.data) {
            notifications.show({
              color: 'green',
              message: res.data.message,
            });

            setBioLinkBlock(DEFAULT_LINK_BLOCK_VALUES);
            reload();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <Text ta="center" px={'md'} pb={'md'} fz={14}>
        Add multiple website links to your profile with the Link Block. Use this block to create
        compelling call-to-actions for things that matter to you. You can add up to 5 links.
      </Text>
      <Divider />
      <Box p="md">
        <Flex mb={'md'} direction={'column'}>
          <Flex
            align={'flex-end'}
            justify={'end'}
            style={{
              flexGrow: 1,
            }}
          >
            <Switch
              size="xs"
              label="Show title"
              checked={bioLinkBlock?.is_show_title_enabled}
              onChange={(e) => {
                setBioLinkBlock({
                  ...bioLinkBlock,
                  is_show_title_enabled: e.currentTarget.checked,
                });
              }}
            />
          </Flex>
          <TextInput
            w={'100%'}
            label="Add a title"
            placeholder="Your title for the link block"
            value={bioLinkBlock?.title}
            error={bioLinkBlock?.title === '' && bioLinkBlock?.is_show_title_enabled}
            onChange={(e) => {
              setBioLinkBlock({
                ...bioLinkBlock,
                title: e.currentTarget.value,
              });
            }}

          />
        </Flex>

        {bioLinkBlock.links.length > 0 && (
          <Fieldset>
            {bioLinkBlock.links.map((link, index) => (
              <>
                <Flex
                  align={'center'}
                  justify={'end'}
                  style={{
                    flexGrow: 1,
                  }}
                  mt={4}
                >
                  <Switch
                    size="xs"
                    label="Show link"
                    checked={link.is_show_title_enabled}
                    onChange={(e) => {
                      const newLinks = bioLinkBlock.links;
                      newLinks[index].is_show_title_enabled = e.currentTarget.checked;
                      setBioLinkBlock({
                        ...bioLinkBlock,
                        links: newLinks,
                      });
                    }}
                  />
                  <Tooltip label="Remove">
                    <ActionIcon
                      onClick={() => {
                        const newLinks = bioLinkBlock.links;
                        newLinks.splice(index, 1);
                        setBioLinkBlock({
                          ...bioLinkBlock,
                          links: newLinks,
                        });
                      }}
                      size={'xs'}
                      ml={'md'}
                      variant="subtle"
                      color="red"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </ActionIcon>
                  </Tooltip>
                </Flex>
                <TextInput
                  mb={'md'}
                  label="Title"
                  placeholder="Add a title"
                  value={link.title}
                  leftSection={
                    <>
                      <FontAwesomeIcon icon={faText} />
                    </>
                  }
                  onChange={(e) => {
                    const newLinks = bioLinkBlock.links;
                    const value = e.currentTarget.value;
                    newLinks[index].title = value;
                    setBioLinkBlock({
                      ...bioLinkBlock,
                      links: newLinks,
                    });
                  }}
                />
                <TextInput
                  value={link.url}
                  mb={'md'}
                  styles={{
                    label: {
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    },
                    input: {
                      marginTop: '10px', // Add some space between the label and the input
                    },
                  }}
                  label={
                    <>
                      <span>URL</span>
                      <Switch
                        size="xs"
                        label="Open link in new tab"
                        labelPosition="right"
                        styles={{
                          root: { display: 'flex', alignItems: 'center' },
                          label: { marginRight: '8px', fontSize: '12px' , fontWeight: 'normal'},
                        }}
                        checked={bioLinkBlock.open_new_tab ?? true}
                        onChange={(e) => {
                          setBioLinkBlock({
                            ...bioLinkBlock,
                            open_new_tab: e.currentTarget.checked,
                          });
                        }}
                      />
                    </>
                  }
                  placeholder="i.e. 'https://website.com', 'tel:+01273123456', 'mailto:support@website.com'"
                  leftSection={
                    <>
                      <FontAwesomeIcon icon={faLink} />
                    </>
                  }
                  onChange={(e) => {
                    const newLinks = bioLinkBlock.links;
                    newLinks[index].url = e.currentTarget.value;
                    setBioLinkBlock({
                      ...bioLinkBlock,
                      links: newLinks,
                    });
                  }}
                />

              </>
            ))}
          </Fieldset>
        )}

        <Center mt={'md'}>
          <Button size="xs" variant="outline" color="primary" onClick={handleAddLink()}>
            {bioLinkBlock.links.length === 0 ? 'Add a link' : '+ Add more links'}
          </Button>
        </Center>
        <Divider my={'md'} />
        <Center>
          <Group>
            <Button onClick={handleAddBlock}>
              {bioLinkBlock._id ? 'Update Block' : 'Add Block'}
            </Button>
          </Group>
        </Center>
      </Box>
    </>
  );
};
